// IMPORTS
@import (reference) "../settings.less";

// PROMO BANNERS
.icon-banners-wrapper {
    .grid();
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
    gap: 36px;
    width: 100%;
    margin-top: 80px;
}

.icon-banner {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    > figure {
        width: 96px;
        aspect-ratio: 1;

        img {
            .img-contain();
        }
    }

    > p {
        font-weight: 300;
        line-height: 1.3;
        font-size: 16px;
        text-align: center;
        color: @header-color;
    }
}

// ALTERNATING BANNERS
.alternating-banners-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100vw;
    max-width: 1920px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.alternating-banner {
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(even) {

        .alternating-image {
            order: 1;

            img {
                transform: translateX(50px);
            }
        }
    }

    .alternating-image {
        width: 50%;
        aspect-ratio: 1;
        flex-shrink: 0;

        img {
            .img-crop();
            opacity: 0;
            transform: translateX(-50px);
            transition: 0.5s all 0.5s ease-in-out;

            &.loaded {
                opacity: 1;
                transform: none;
            }
        }

        &:only-child img {
            transform: none !important;
        }
    }

    .alternating-text {
        display: flex;
        flex-direction: column;
        margin-inline: 70px;
        align-items: flex-start;

        &:only-child {
            align-items: center;
            text-align: center;
            max-width: 850px;
        }

        .headline {
            display: flex;
            flex-direction: column;
            gap: 32px;
            margin-bottom: 32px;

            &:after {
                content: '';
                width: 1px;
                height: 32px;
                background: @main-color;
            }
        }

        p:last-child {
            margin-bottom: 12px;
        }

        a {
            margin-top: 48px;
            text-transform: uppercase;
            font-weight: 600;
            padding: 12px 40px;
            font-size: 12px;
            color: @header-color;
            border: 1px solid @header-color;
            letter-spacing: 0.1em;
            margin-bottom: 16px;

            @media (min-width: 1280px) {
                &:hover {
                    border-color: @main-color;
                }
            }
        }
    }

    > *:only-child {
        margin-block: var(--rowGap);
    }
}

// SLIDING BANNERS
.sliding-banners-wrapper {
    position: relative;
}

@media (max-width: 1500px) {
    .sliding-banners-wrapper {
        margin-inline: 32px;
        gap: 32px;
    }
}

.sliding-banner {
    width: ~"calc((100% - ((var(--prodSwiperCols) - 1) * var(--mainpageSliderGap))) / var(--prodSwiperCols))";
    aspect-ratio: 1;
    margin-right: var(--mainpageSliderGap);

    img {
        .img-crop();
    }
}